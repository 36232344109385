@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* display: flex;  */
  padding: 0 24px;
  margin: 0;
  height: 100vh;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-image: url(../public/logo-bg.png) #eee;
  background: url(../public/logo-bg.png) #eee;
  background-size: 100px;
}

.App {
  color: #72a24d;
}

/* @media screen and (min-width: 990px) {
  body {
    background-image: url(./assets/shield-white-trans.png) #eee;
    background: url(./assets/shield-white-trans.png) #eee;
    background-size: 100px;
  }
} */
